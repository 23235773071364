.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3rem;
}

.clockface-item {
  display: flex;
  align-items: center;
}

.separator {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;
}
