.button {
  display: block;
  margin: .5rem;
  padding: .5rem 1rem;
  background: var(--color-blank-darker);
  border: none;
  border-radius: 25px;

  font-size: .75rem;
  text-decoration: none;
  letter-spacing: 1.5px;
  font-weight: 400;

  text-transform: uppercase;
  color: var(--color-dark);

  outline: none;
  cursor: pointer;
  box-shadow: var(--neu-convex-bow-shadow-calm);
  transition: 399ms ease-in-out;
}
.button:hover {
  box-shadow: var(--neu-convex-bow-shadow-hover);
}
/* button:active {
  box-shadow: var(--neu-convex-bow-shadow-active);
} */

.button.pressed {
  box-shadow: var(--neu-convex-bow-shadow-active);
  color: var(--color-attention);
  transition: 399ms ease-in-out;
}

.button-round {
  --button-size: 2.75rem;
  width: var(--button-size);
  height: var(--button-size);
  padding: 0 0 .1rem 0;
  font-size: 1.5rem;
  border-radius: 50%;
}
