.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}
.wrapper:not(:last-child) {
  margin: 0 0 2rem 0;
}

.date {
  margin-bottom: .5rem;
  font-weight: 400;
}

.clockface-item {
  display: flex;
  align-items: center;
}

.form-wrapper {
  display: flex;
}

.separator {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 .5rem;
}
