h1, h2 {
  text-transform: uppercase;
  font-weight: 200;
  line-height: 1.5;
  text-align: center;
}

h1 {
  font-size: 4rem;
}

h2 {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  /* font-size: .75rem; */
  padding-bottom: .75rem;
  /* letter-spacing: 3px; */
  /* font-weight: 700; */
  /* text-decoration: underline; */
  margin: auto;
  color: var(--color-dark-darkest);
}
