.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25vh;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  letter-spacing: 5px;
  margin-bottom: 2.5rem;
  line-height: 1;
  color: var(--color-dark-darker);
  font-weight: 200;
}

.logo-small {
  font-size: 1.25rem;
  color: var(--color-dark-darkest);
  font-weight: 400;
  letter-spacing: 7px;
  padding-left: .5rem;
  margin-top: .25rem;
}

.text-primary {
  color: var(--color-primary);
  transition: 399ms ease-in-out;
}

.text-attention {
  color: var(--color-attention);
}
