.tutorial {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
}
.slogan {
  padding-bottom: 1.25rem;
}

.instructions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: .9rem;
  color: var(--color-dark-darker);
}
.instructions-item {
  margin: 0 1.25rem;
  display: flex;
}
.instruction-item-text {
  padding: .08rem 0;
}

.icon-play {
  color: var(--color-primary);
  font-size: 1.25rem;
  vertical-align: middle;
  padding: .2rem .3rem .3rem 0;
}

.icon-pause {
  color: var(--color-attention);
  font-size: 1.15rem;
  vertical-align: middle;
  padding: .3rem .35rem .3rem 0;
}
