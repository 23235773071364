.input {
  border: 0;
  border-radius: .25rem;
  padding: 1rem;
  width: 3rem;
  background: var(--color-blank-darker);
  
  color: var(--color-dark);
  font-size: 1.75rem;
  font-weight: 400;
  text-align: right;
  letter-spacing: 6px;

  outline: none;
  cursor: pointer;
  transition: 399ms ease-in-out;
  box-shadow: var(--neu-convex-bow-shadow-calm);
  -webkit-appearance: none;
}
.input:hover {
  box-shadow: var(--neu-convex-bow-shadow-hover);
}
.input:active, input:focus, input:focus:hover {
  box-shadow: var(--neu-convex-bow-shadow-active);
}

.input.small {
  padding: .7rem;
  width: 2rem;
  font-size: 1.25rem;
}

/* REMOVE NUMBER INPUT ARROWS */
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}
/* */

textarea:focus, .input:focus{
  outline: none;
  color: var(--color-primary);
}

.text-attention {
  color: var(--color-attention);
  transition: none;
}
