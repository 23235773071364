.timer-button {
  --button-size: 6.5rem;
  width: var(--button-size);
  height: var(--button-size);
  margin: 0 auto;
  border-radius: 50%;
  color: var(--color-primary);
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: .2rem;
  text-align: center;
}

.timer-button > i {
  font-size: 2.5rem;
}

.timer-button.pressed {
  transition: 399ms ease-in-out;
  box-shadow: var(--neu-convex-bow-shadow-active);
  color: var(--color-attention);
}
