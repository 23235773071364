.donate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: calc(var(--padding-section-bottom) - 1rem);
}
.donate-images {
  padding: 0 0 .5rem 0;
}
.button-donate {
  padding: .5rem 1.25rem;
  margin: auto;
  text-align: center;
  color: var(--color-attention);
  text-decoration: none;
  font-size: .9rem;
  font-weight: 500;
}
