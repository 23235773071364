.images-list {
  display: flex;
  justify-content: center;
}

img {
  --img-size: 6.5rem;
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;
  padding: 1rem 0.5rem;
  /* margin: .2rem; */
  border: none;
  object-fit: cover;
}
