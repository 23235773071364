:root {
  --color-blank: rgb(248, 246, 246);
  --color-blank-darker: rgb(240, 240, 243);
  --color-dark: rgb(73, 73, 73);
  --color-dark-lighter: rgb(89, 89, 89);
  --color-dark-darker: rgb(63, 63, 63);
  --color-dark-darkest: rgb(51, 51, 51);
  --color-primary: rgb(20, 130, 140);
  --color-primary-lighter: rgb(17, 137, 148);
  --color-primary-lightest: rgba(17, 137, 148, 0.15);
  --color-border: rgba(17, 137, 148, 0.15);
  --color-attention: rgb(214, 78, 5);
  --color-attention-lighter: rgba(214, 78, 5, .25);

  --padding-section-bottom: 11rem;

  /* Neumorphism */
  --neu-convex-bow-shadow-calm: -3px -3px 7px rgba(249, 247, 247, .8),
                                -3px -3px 5px rgba(249, 247, 247, .6),
                                3px 3px 3px rgba(249, 247, 247, .085),
                                3px 3px 5px rgba(0, 0, 0, .2);
  --neu-convex-bow-shadow-active: inset -2px -2px 6px rgba(249, 247, 247, .8),
                                  inset -2px -2px 4px rgba(249, 247, 247, .6),
                                  inset 2px 2px 2px rgba(249, 247, 247, .085),
                                  inset 2px 2px 4px rgba(0, 0, 0, .2);
  --neu-convex-bow-shadow-hover: -3px -3px 7px rgba(249, 247, 247, .7),
                                -3px -3px 5px rgba(249, 247, 247, .5),
                                3px 3px 3px rgba(249, 247, 247, .1),
                                3px 3px 5px rgba(0, 0, 0, .15);
}
