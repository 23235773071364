html {
  font-size: 16px;
}
@media (min-width: 768px) { 
  html {
    font-size: 24px;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.75;
  background: var(--color-blank-darker);
  min-height: 100vh;
  margin: 0 auto 0 auto;
  color: var(--color-dark);
  letter-spacing: 1px;
  font-weight: 400;
}

section {
  padding-bottom: var(--padding-section-bottom);
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a {
  color: var(--color-primary);
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

footer {
  font-size: .75rem;
  text-transform: uppercase;
  text-align: center;
  padding: .5rem 0;
}
.copyright {
  color: var(--color-dark-lighter);
}
